import { FunctionComponent, useEffect, useState, useRef, useMemo } from "react";
import "./CallScreen.scss";

import { useMobileOrientation } from "react-device-detect";
import CallHeader from "../../Common Components/CallHeader/CallHeader";
import ActionToolBar from "../../Common Components/ActionToolBar/ActionToolBar";
import TranscriptPanel from "../../Common Components/TransciptPanel/TranscriptPanel";
// import ChatPanel from "../../Common Components/New-ChatPanel/ChatPanel";
import ParticipantsPanel from "../../Common Components/ParticipantsPanel/ParticipantsPanel";
import ParticipantTile from "../../Common Components/ParticipantTile/ParticipantTile";
import {
  getSmallTileSize,
  getTileSize,
  isDesktopDevice,
  isTabletOrIpad,
  isMobileDevice,
  getAddressBarHeight,
} from "../../Utility/Utils";
import { useSelector } from "react-redux";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import InviteViaEmailPopUp from "../InviteParticipant/InviteViaEmailPopup";
import { Track } from "proconf-web-sdk";

const CallScreen: FunctionComponent = () => {
  const [openInvitePopup, setOpenInvitePopup] = useState(false);
  const [showChatPanel, setShowChatPanel] = useState(false);
  const [showTranscriptPanel, setShowTranscriptPanel] = useState(false);
  const [showParticipantsPanel, setShowParticipantsPanel] = useState(false);
  const [activeRecording, setActiveRecording] = useState(false);
  const [callTileCount, setCallTileCount] = useState(0);
  const [activePanel, setActivePanel] = useState("");
  const [callScreenHeight, setCallScreenHeight] = useState("100vh")
  const [bigTileSize, setBigTileSize] = useState({
    tileHeight: 0,
    tileWidth: 0,
  });
  const [smallTileSize, setSmallTileSize] = useState({
    tileHeight: 0,
    tileWidth: 0,
    noOfUsersToFit: 0,
  });

  const sidePanelRef = useRef<HTMLDivElement>(null);

  const { isLandscape, isPortrait } = useMobileOrientation();

  const participants = useSelector(
    (state: any) => state.ParticipantReducer?.participants
  );
  const isScreenShared = useSelector(
    (state: any) => state.ProConfReducer?.isRemoteSSOn
  );
  const screensharingParticicpants = useSelector((state: any) => {
    return state.ParticipantReducer?.screenSharingParticipants;
  });

  const {
    audioMute,
    videoMute,
    screenShareStarted,
    isNoiseCancellationEnabled,
  } = useSelector((state: any) => state.ToolbarReducer);

  const toggleChatPanel = () => {
    // !showChatPanel ? setActivePanel("CHAT") : setActivePanel("");
    if (!showChatPanel) {
      setActivePanel("CHAT");
      if (!isDesktopDevice) {
        setShowTranscriptPanel(false);
        setShowParticipantsPanel(false);
      }
    } else {
      setActivePanel("");
    }
    setShowChatPanel(!showChatPanel);
  };

  const toggleTranscriptPanel = () => {
    // !showTranscriptPanel ? setActivePanel("TRANSCRIPT") : setActivePanel("");
    if (!showTranscriptPanel) {
      setActivePanel("TRANSCRIPT");
      if (!isDesktopDevice) {
        setShowChatPanel(false);
        setShowParticipantsPanel(false);
      }
    } else {
      setActivePanel("");
    }
    setShowTranscriptPanel(!showTranscriptPanel);
  };

  const toggleParticipantsPanel = () => {
    // !showParticipantsPanel
    //   ? setActivePanel("PARTICIPANTS")
    //   : setActivePanel("");
    if (!showParticipantsPanel) {
      setActivePanel("PARTICIPANTS");
      if (!isDesktopDevice) {
        setShowTranscriptPanel(false);
        setShowParticipantsPanel(false);
      }
    } else {
      setActivePanel("");
    }
    setShowParticipantsPanel(!showParticipantsPanel);
  };

  const onClickRecording = () => {
    setActiveRecording(!activeRecording);
  };

  // const handleOrientationChange = () => {
  //   if (activePanel === "PARTICIPANTS") {
  //     setShowParticipantsPanel(true);
  //   } else if (activePanel === "TRANSCRIPT") {
  //     setShowTranscriptPanel(true);
  //   } else if (activePanel === "CHAT") {
  //     setShowChatPanel(true);
  //   }
  //   setTileLayout();
  // };

  const calculateCallScreenHeight = () => {
    if (isMobileDevice || isTabletOrIpad) {
      const addressBarHeight = getAddressBarHeight();
      setCallScreenHeight(`calc(100vh - ${addressBarHeight}px)`);
    }
  }

  const onWindowResizeOrOrientationChange = () => {
    setTileLayout();
    calculateCallScreenHeight();
  }

  useEffect(() => {
    // alert("calling setTileLayout in componentDidMount")
    setTileLayout();
    calculateCallScreenHeight();
    if (!isDesktopDevice) {
      window.addEventListener("resize", onWindowResizeOrOrientationChange);
      window.addEventListener("orientationchange", onWindowResizeOrOrientationChange);
    }
    return () => {
      window.removeEventListener("resize", onWindowResizeOrOrientationChange);
      window.removeEventListener("orientationchange", onWindowResizeOrOrientationChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // alert("calling setTileLayout in componentDidUpdate")
    setTileLayout();
    if (!isDesktopDevice) {
      window.addEventListener("resize", onWindowResizeOrOrientationChange);
      window.addEventListener("orientationchange", onWindowResizeOrOrientationChange);
    }
  }, [participants.length, callTileCount, isScreenShared, isLandscape, activePanel]);

  useEffect(() => {
    setCallTileCount(1);
    setTimeout(() => {
      participants?.map((singleParticipant: any) => {
        let tracks = Array.from(
          singleParticipant.videoTracks.values()
        ) as Track[];
        if (tracks[0]?.isEnabled && tracks[0]?.getVideoType() === "camera") {
          tracks[0].attach(
            document.getElementById(
              "video-" + singleParticipant?.sid
            ) as HTMLVideoElement
          );
        }
        return null;
      });
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenShared]);

  useMemo(() => {
    // this hook is required to attach the video streams 
    // except for the first user to the thumbnails in sidepanel
    // when any panel is opened OR in the main container when panel is closed 
    if (isMobileDevice || (isTabletOrIpad && isPortrait)) {
      let callTiles = 1;
      setTimeout(() => {
        participants
          .slice(callTiles, participants.length)
          // .map((singleParticipant: any) => {
          .forEach((singleParticipant: any) => {
            let tracks = Array.from(
              singleParticipant.videoTracks.values()
            ) as Track[];
            if (
              tracks[0]?.isEnabled &&
              tracks[0]?.getVideoType() === "camera"
            ) {
              tracks[0].attach(
                document.getElementById(
                  "video-" + singleParticipant?.sid
                ) as HTMLVideoElement
              );
            }
          });
      }, 1000);
    }
    // }, [showChatPanel, showParticipantsPanel, showTranscriptPanel]);
  }, [activePanel]);

  const setTileLayout = () => {
    let showPanelsHorizontally = isDesktopDevice || (isTabletOrIpad && isLandscape);
    let parentElement = document.getElementById(showPanelsHorizontally ? "callbody" : "calltiles");
    if (parentElement) {
      const size: DOMRect = parentElement.getBoundingClientRect();
      if (showPanelsHorizontally) {
        size.width =
          size?.width -
          (showChatPanel || showTranscriptPanel
            ? document
              .getElementById("leftnavcontainer")
              ?.getBoundingClientRect().width ?? 0
            : 0) -
          (showParticipantsPanel
            ? document
              .getElementById("participantspanel")
              ?.getBoundingClientRect().width ?? 0
            : 0) -
          (document.getElementById("sidetilespanel")?.getBoundingClientRect()
            .width || 0);
      }
      let isLargerHeight = false;
      if (size.height > size.width && participants.length === 1 && !activePanel.length) {
        isLargerHeight = true;
        // if (activePanel.length
        //   // activePanel === "PARTICIPANTS" ||
        //   // activePanel === "TRANSCRIPT" ||
        //   // activePanel === "CHAT"
        // ) {
        //   isLargerHeight = false;
        // } else {
        //   isLargerHeight = true;
        // }
      }
      setBigTileSize(
        getTileSize(size, callTileCount, participants, isLargerHeight)
      );
      if (participants.length - callTileCount > 0) {
        setSmallTileSize(getSmallTileSize(sidePanelRef.current, 10, 10));
      }
    }
  };

  useEffect(() => {
    // setTileLayout();
    // if (isScreenShared) {
    //   setCallTileCount(1);
    // } else if ((isMobileDevice || isTabletOrIpad) && isPortrait) {

    // tile layout
    // Desktop: 4 
    // Mobile: portrait: when no panel is open: 3
    //                 : when any panel is open: 1
    //        landscape: when no panel is open: 3
    //                 : when any panel is open: 3   
    // Tab / ipad: portrait: when no panel is open: 4
    //                 : when any panel is open: 1
    //        landscape: when no panel is open: 4
    //                 : when any panel is open: 4   
    let callTileCount = isScreenShared ? 1 : 4;
    if ((isMobileDevice || isTabletOrIpad) && isPortrait && activePanel.length) {
      callTileCount = 1;
    } else if (isMobileDevice && !activePanel.length) {
      callTileCount = 3;
    }
    setCallTileCount(callTileCount);

    // if (isDesktopDevice || (isTabletOrIpad && isLandscape)) {
    //   setCallTileCount(4);
    // } else {
    //   // will be applicable for mobile, tablet and ipad for portrait mode
    //   if (activePanel.length) {
    //     setCallTileCount(1);
    //   } else if (isTabletOrIpad) {
    //     setCallTileCount(4);
    //   } else if (isMobileDevice) {
    //     setCallTileCount(3);  // confirm with Ishwari 
    //   }
    // }

    // if ((isMobileDevice || isTabletOrIpad) && !isDesktopDevice) {
    // if (!isDesktopDevice) {
    //   if (/*showChatPanel && */ activePanel === "CHAT") {
    //     setShowTranscriptPanel(false);
    //     setShowParticipantsPanel(false);
    //   } else if (/*showTranscriptPanel &&*/ activePanel === "TRANSCRIPT") {
    //     setShowParticipantsPanel(false);
    //     setShowChatPanel(false);
    //   } else if (/*showParticipantsPanel &&*/ activePanel === "PARTICIPANTS") {
    //     setShowTranscriptPanel(false);
    //     setShowChatPanel(false);
    //   }
    // }
  }, [
    // showChatPanel,
    // showTranscriptPanel,
    // showParticipantsPanel,
    activePanel,
    isLandscape,
    isScreenShared,
  ]);

  return (
    <div
      className={
        isMobileDevice &&
          isLandscape &&
          activePanel.length
          ? "callscreen scrollableView"
          : "callscreen"
      }
      style={{ height: callScreenHeight }}
    >
      <CallHeader showRecordingIcon={activeRecording} />
      <div className="callbody" id="callbody">
        {(showChatPanel || showTranscriptPanel) && (
          <div className="leftnavcontainer" id="leftnavcontainer">
            {showTranscriptPanel && (
              <TranscriptPanel toggleTranscriptPanel={toggleTranscriptPanel} />
            )}
            {/* Hide chat panel */}
            {/* {showChatPanel && <ChatPanel toggleChatPanel={toggleChatPanel} />} */}
          </div>
        )}
        <div className="callbodycontainer">
          <div className="callmaincontainer">
            <div className="calltiles" id="calltiles">
              {isScreenShared ? (
                <ParticipantTile
                  participant={screensharingParticicpants[0]}
                  screenShared={true}
                />
              ) : (
                participants
                  .slice(0, Math.min(participants.length, callTileCount))
                  .map((participant: any) => (
                    <ParticipantTile
                      key={participant?.sid}
                      tileSize={bigTileSize}
                      participant={participant}
                      allParticipants={participants}
                    />
                  ))
              )}
            </div>
          </div>
          {participants.slice(callTileCount).length !== 0 && (
            <div
              className="sidetilespanel"
              id="sidetilespanel"
              ref={sidePanelRef}
            >
              {participants
                .slice(isScreenShared ? null : callTileCount)
                .slice(
                  0,
                  Math.min(
                    smallTileSize.noOfUsersToFit,
                    participants.slice(isScreenShared ? null : callTileCount)
                      .length
                  )
                )
                .map((participant: any) => (
                  <ParticipantTile
                    key={participant?.sid}
                    tileSize={smallTileSize}
                    participant={participant}
                    smallTile={true}
                  />
                ))}
            </div>
          )}
        </div>
        {showParticipantsPanel && (
          <ParticipantsPanel
            toggleParticipantsPanel={toggleParticipantsPanel}
            setInvitePopup={setOpenInvitePopup}
          />
        )}

        {openInvitePopup && (
          <PortalPopup placement="Centered">
            <InviteViaEmailPopUp setInvitePopup={setOpenInvitePopup} />
          </PortalPopup>
        )}
      </div>
      <ActionToolBar
        activeRecording={activeRecording}
        showChatPanel={showChatPanel}
        showParticipantsPanel={showParticipantsPanel}
        showTranscriptPanel={showTranscriptPanel}
        toggleChatPanel={toggleChatPanel}
        toggleTranscriptPanel={toggleTranscriptPanel}
        toggleParticipantsPanel={toggleParticipantsPanel}
        toggleRecording={onClickRecording}
        isScreenShared={isScreenShared}
        audioMute={audioMute}
        videoMute={videoMute}
        screenShareStarted={screenShareStarted}
        participants={participants}
        isNoiseCancellationEnabled={isNoiseCancellationEnabled}
      />
    </div>
  );
};

export default CallScreen;
