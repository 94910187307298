import { store } from "../Redux/store";
import {
  isMobile,
  isTablet,
  isDesktop,
  isMobileOnly,
} from "react-device-detect";

/**
 *
 * @param dateString date to be formatted
 * @returns  format date based on current week or past date
 */
export function getFormattedDateTime(dateString: string) {
  const date = new Date(dateString);

  // Current date and time
  const now = new Date();

  // Check if date is within the current week
  const isCurrentWeek =
    date >=
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());

  // Options for formatting the date
  const options = {
    hour: "2-digit", // 2-digit hour (e.g., 08)
    minute: "2-digit", // 2-digit minute (e.g., 10)
    hour12: true, // use 24-hour format
  };

  // Format the date and time
  let formattedDate;
  if (isCurrentWeek) {
    // Display only day and time for the current week
    const dayOptions = {
      weekday: "long", // full day name (e.g., "Tuesday")
      hourCycle: "h12",
      ...options,
    };
    // @ts-ignore
    formattedDate = new Intl.DateTimeFormat("en-US", dayOptions).format(date);
  } else {
    // Display full date and time for other weeks
    const fullOptions = {
      // weekday: 'long', // full day name (e.g., "Tuesday")
      // year: 'numeric', // 4-digit year (e.g., 2024)
      month: "long", // full month name (e.g., "June")
      day: "numeric", // numeric day (e.g., 20)
      ...options,
    };
    // @ts-ignore
    formattedDate = new Intl.DateTimeFormat("en-US", fullOptions).format(date);
  }

  return formattedDate;
}

export const formatMillisecondsToDate = (millis: number): string => {
  const date = new Date(millis);
  return date.toLocaleString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Set to true for 12-hour format
  });
};

export function convertUTCToLocalTimezone(utcDateString: string) {
  // Parse the UTC datetime string into a Date object
  // Ex: 1726123167 => 12 September 2024 at 12:23:54 pm
  // @ts-ignore
  const utcDate = new Date(utcDateString * 1000); // Adding ' UTC' to indicate it's UTC time

  // Get browser's timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Define options for formatting the date
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: timezone,
  };

  // Convert to local time and format
  const localDateString = utcDate
    // @ts-ignore
    .toLocaleString("en-US", options)
    .replace(",", ""); // Remove comma for desired format

  return localDateString;
}

export function getFilteredConfiguration(configurations: object) {
  // Using Object.entries and reduce to filter properties with true values
  const filteredObj = Object.entries(configurations)
    .filter(([key, value]) => value === true)
    .reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {});

  return filteredObj;
}

export const handleKeyPressForMeetingName = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  // Allow only alphabetic characters and whitespace
  if (
    (e.key >= "A" && e.key <= "Z") || // A-Z
    (e.key >= "a" && e.key <= "z") || // a-
    (e.key >= "0" && e.key <= "9") // a-z
  ) {
    // Append the typed character to the current text
    return;
  }
  // Prevent default behavior for all other keys
  e.preventDefault();
};

export const validateEmail = (email: string): boolean => {
  // Regular expression for validating email addresses
  if (!email) {
    return true;
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export function getRoomFromURL() {
  // Get the URL of the current page
  const currentURL = window.location.href;
  let segments;

  // Split the URL into an array of segments using the forward slash as a delimiter
  if (currentURL.includes("/")) {
    segments = currentURL?.split("/");
  } else if (currentURL.includes("/#/")) {
    segments = currentURL?.split("/#/");
  } else if (currentURL.includes("#/")) {
    segments = currentURL?.split("#/");
  } else {
    segments = currentURL?.split("");
  }

  // Access the last segment of the array to get the last part of the URL
  let getRoomFromURL = segments[segments?.length - 1];
  getRoomFromURL = getRoomFromURL.replace("#/", "");

  // Split the input string at the "?"
  const [roomId, queryString] = getRoomFromURL.split("?");

  store.dispatch({ type: "SET_ROOM_ID", payload: roomId });

  // Create a URLSearchParams object to handle the query string
  const params = new URLSearchParams(queryString);

  // Extract the room_name parameter
  const roomName = params.get("room_name");

  console.log(
    "_getGuestRoomName getRoomFromURL: ",
    getRoomFromURL,
    roomId,
    roomName
  );

  return roomName;
}

const isIpad = () => {
  return /iPad/i.test(navigator.userAgent);
};

export const isMobileDevice =
  !isDesktop && !isTablet && isMobile && isMobileOnly;
export const isTabletOrIpad =
  !isDesktop && !isMobileOnly && (isTablet || isIpad());
export const isDesktopDevice =
  !isMobile && !isMobileOnly && !isTablet && isDesktop;
// isDesktop || window.innerWidth > 1200;

export const getTileSize = (
  size: any,
  tileCount: number,
  participants: Array<any>,
  isLargerHeight: boolean
) => {
  // let size = parentElement?.getBoundingClientRect();
  // if (tileCount && isDesktopDevice && !isTabletDevice && !isMobileDevice) {
  //   size.width -= 200;
  // }
  if (size) {
    let tileSize = getTilePlacements(
      size?.width,
      size?.height,
      Math.min(participants.length, tileCount),
      isLargerHeight,
      participants.length === 1 && isDesktop ? 5 : 2,
      10
    );
    return tileSize;
  }
};

const getTilePlacements = (
  containerWidth: number,
  containerHeight: number,
  noOfUsers: number,
  isLargerHeight: boolean,
  verticalMargin = 0,
  horizontalMargin = 0
): any => {
  let possibleLayouts = [];
  let bestSuitableLayout = { tileSize: {} };
  for (let tilesPerRow: number = 1; tilesPerRow <= noOfUsers; tilesPerRow++) {
    let totalRows = Math.ceil(noOfUsers / tilesPerRow); // 3 | 2
    possibleLayouts.push(
      getPossibleTileSizes(
        containerWidth,
        containerHeight,
        verticalMargin,
        horizontalMargin,
        totalRows,
        tilesPerRow,
        noOfUsers,
        isLargerHeight
      )
    );
  }

  if (containerWidth > containerHeight) {
    bestSuitableLayout = possibleLayouts.reduce(
      (prevLayoutWithMoreWidth, layout) => {
        return layout?.tileSize.tileWidth >
          prevLayoutWithMoreWidth?.tileSize.tileWidth
          ? layout
          : prevLayoutWithMoreWidth;
      },
      possibleLayouts[0]
    );
  } else {
    bestSuitableLayout = possibleLayouts.reduce(
      (prevLayoutWithMoreHeight, layout) => {
        return layout?.tileSize.tileHeight >
          prevLayoutWithMoreHeight?.tileSize.tileHeight
          ? layout
          : prevLayoutWithMoreHeight;
      },
      possibleLayouts[0]
    );
  }
  console.log(
    "bestSuitableLayout =========",
    bestSuitableLayout?.tileSize,
    containerWidth,
    containerHeight
  );
  return bestSuitableLayout?.tileSize;
};

const getPossibleTileSizes = (
  containerWidth: number,
  containerHeight: number,
  verticalMargin: number,
  horizontalMargin: number,
  totalRows: number,
  tilesPerRow: number,
  noOfUsers: number,
  isLargerHeight: boolean
) => {
  let layout = {
    totalRows,
    tilesPerRow,
    tileSize: { tileHeight: 0, tileWidth: 0 },
  };
  let tileWidth = containerWidth / tilesPerRow; // 1200 / 2 = 600  | 400
  tileWidth -= (tilesPerRow + 1) * horizontalMargin;
  let tileHeight = tileWidth * (9 / 16); // 338 |
  if (isLargerHeight) {
    tileHeight = tileWidth * (16 / 9);
  }
  let totalTileHeight = tileHeight * totalRows; // 1012
  if (totalTileHeight < containerHeight) {
    let totalVerticalMargin = (totalRows + 1) * verticalMargin;
    totalTileHeight += totalVerticalMargin;
    if (totalTileHeight < containerHeight) {
      layout.tileSize = { tileWidth, tileHeight };
    }
  }

  tileHeight = containerHeight / totalRows;
  tileHeight -= (totalRows + 1) * verticalMargin;
  tileWidth = tileHeight * (16 / 9); // 1200 / 2 = 600  | 400
  if (isLargerHeight) {
    tileWidth = tileHeight * (9 / 16);
  }
  let totalTileWidth = tileWidth * tilesPerRow; // 1012
  if (totalTileWidth < containerWidth) {
    let totalHorizontalMargin = (tilesPerRow + 1) * horizontalMargin;
    totalTileWidth += totalHorizontalMargin;
    if (totalTileWidth < containerWidth) {
      layout.tileSize = { tileWidth, tileHeight };
    }
  }
  return layout;
};

export const getSmallTileSize = (
  parentElement: any,
  verticalMargin: number,
  horizontalMargin: number
) => {
  if (parentElement) {
    let size = parentElement?.getBoundingClientRect();
    let containerWidth = size.width;
    let containerHeight = size.height;
    let tileHeight = 1;
    let tileWidth = 1;
    let noOfUsersToFit = 1;

    if (containerWidth < containerHeight) {
      tileWidth = containerWidth - horizontalMargin * 2;
      tileHeight = tileWidth * (9 / 16);
      noOfUsersToFit = Math.floor(containerHeight / tileHeight);
      if (
        tileHeight * noOfUsersToFit + (noOfUsersToFit - 1) * verticalMargin >
        containerHeight
      ) {
        noOfUsersToFit -= 1;
      }
    } else {
      tileHeight = containerHeight - verticalMargin * 2;
      tileWidth = tileHeight * (16 / 9);
      noOfUsersToFit = Math.floor(containerWidth / tileWidth);
      if (
        tileWidth * noOfUsersToFit + (noOfUsersToFit - 1) * horizontalMargin >
        containerWidth
      ) {
        noOfUsersToFit -= 1;
      }
    }
    return { totalRows: 1, tileWidth, tileHeight, noOfUsersToFit };
  }
  return { totalRows: 1, tileWidth: 0, tileHeight: 0, noOfUsersToFit: 0 };
};

export const getAddressBarHeight = () => {
  // Create a temporary div to calculate full viewport height
  const tempDiv = document.createElement("div");
  tempDiv.style.height = "100vh"; // This will represent the full viewport height
  document.body.appendChild(tempDiv);

  // Get the height of the full viewport
  const fullViewportHeight = tempDiv.clientHeight;
  document.body.removeChild(tempDiv);

  // Calculate the difference between the full viewport and current visible height
  const addressBarHeight = fullViewportHeight - window.innerHeight;

  return addressBarHeight > 0 ? addressBarHeight : 0;
}
